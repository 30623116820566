<!-- 点位管理 -->

<template>
  <div class="main-cnt">
    <div class="title">点位列表</div>

    <audio :src="mp3url" ref="audioMedia" controls v-show="false"></audio>

    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 325px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="ScenicApi.getSoundMuseum" :columns="tableColumns" @edit="openAddDialog"
        @statusChange="statusChange" @showQrcode="onSeeBtn" @audition="onAuditionBtn">
        <template #operate>
          <el-button type="primary" round @click="openDialog('dow')"
            v-if="authData.indexOf('n_ADAFC7F56AA3B986605A066B0D62') != -1">
            下载模板
          </el-button>
          <el-button type="primary" round @click="openDialog('imp')"
            v-if="authData.indexOf('n_5A23F701E11D09F9055DC81A5B3C') != -1">
            导入模板
          </el-button>
          <el-button type="primary" round @click="handleExport"
            v-if="authData.indexOf('n_AF40B8ABF62BF97FC7B2C0EFC98F') != -1">
            批量导出
          </el-button>
          <el-button type="primary" round @click="openAddDialog"
            v-if="authData.indexOf('n_5AFH582KFKI3921A07GEFC3A756E') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增点位
          </el-button>
        </template>

        <!-- <template #auditions="{ scope, row }">
          <el-button @click="onAuditionBtn(row, scope.$index, 1)" v-if="!row.is_play">播放</el-button>
          <el-button @click="onAuditionBtn(row, scope.$index, 1)" v-else>暂停</el-button>
        </template> -->
      </common-table>
    </div>

    <!-- 新增/编辑 点位弹框 -->
    <w-dialog ref="dialogRef" class="add-dialog" :title="isEdit ? '编辑点位' : '新增点位'" width="40%" btnWidth="140px"
      top="10vh" :confirmText="isEdit ? '确认编辑' : '确认新增'" @wConfirm="handleSure" :modalClick="false">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="所属项目" prop="sm_pid">
              <el-select v-model="ruleForm.sm_pid" clearable placeholder="请选择项目" :disabled="isEdit">
                <el-option :label="item.p_name" :value="item.p_id" v-for="item in projectOptions" :key="item.p_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="关联文件" prop="associatedFiles">
              <el-autocomplete v-model.trim="ruleForm.associatedFiles" value-key="smr_name"
                :fetch-suggestions="querySearch" placeholder="请输入文件名称搜索" @select="handleSelect" clearable>
                <template #default="{ item }">
                  <div class="value">{{ item.smr_name }}</div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <!-- 上传文件 -->
    <w-dialog ref="uploadRef" class="add-dialog" title="导入模板" width="40%" btnWidth="140px" top="10vh" confirmText="立即导入"
      @wConfirm="onConfirmBtn" :modalClick="false">
      <div>
        <span class="required">*</span>
        文件名
      </div>
      <div class="upload-files">
        <!-- <FilesUpload :fileLists="fileLists" @uploadFile="uploadFile" @deleteFile="deleteFile"></FilesUpload> -->
        <el-upload class="tem-upload" action="" ref="upload" :limit="1" :file-list="fileLists" :show-file-list="false"
          :http-request="customUpload" accept=".xls,.xlsx" :on-exceed="onExceed">
          <div class="tem-upload-text"><i class="iconfont icon-a-lianhe4"></i></div>
        </el-upload>
        <div class="flex-btw file-style" v-if="!!fileName">
          <div class="text-hidden">{{ fileName }}</div>
          <span class="cursor-pointer" @click="handleRemove">
            <el-icon>
              <Close />
            </el-icon>
          </span>
        </div>
      </div>
    </w-dialog>

    <!-- 试听二维码 -->
    <w-dialog ref="codeVieWDialog" title="二维码" width="30%" :hideFooter="true">
      <div class="flex-center">
        <QRCode :value="codeUrl" :size="200" lever="H"></QRCode>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, reactive, onMounted, computed, watch } from "vue";
  import { BasicApi, ScenicApi } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import { ElMessage } from "element-plus";
  // import FilesUpload from "./filesUpload.vue";
  import QRCode from "qrcode.vue";
  import getBaseUrl from "@/plugins/baseUrl.js";
  import axios from "axios";
  import { Close } from "@element-plus/icons-vue";

  export default {
    name: "PointManagement",
    components: { QRCode, Close },
    // emits: ["submit"],
    // props, { emit }
    setup() {
      const baseUrl = getBaseUrl() ? getBaseUrl() : "";
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);  // 权限
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      // 表格对象
      const tableRef = ref(null);  // 表格配置数据
      const dialogRef = ref(null);  // 新增、编辑对象
      const isEdit = ref(false);  // 当前是否是编辑操作// 项目下拉选项
      const formRef = ref(null);  // 表单对象
      const uploadRef = ref(null);  // 上传文件
      const fileLists = ref([]);
      const projectOptions = ref([]);
      // 验证内容不为空
      const rules = reactive({
        sm_pid: [{ required: true, message: "请选择项目", trigger: "chaneg" }],
        associatedFiles: [{ required: true, message: "请选择关联文件", trigger: "blur" }],
      });
      // 新增、编辑填写内容
      const ruleForm = ref({
        sm_pid: "",  // 项目
        sm_smrid: "",  // 文件id
        associatedFiles: "",  // 关联文件
      });
      const tableColumns = ref([
        {
          prop: "sm_key",
          label: "点位编号",
        },
        {
          prop: "p_name",
          label: "所属项目",
          color: "--text-third-color",
        },
        {
          prop: "smr_name",
          label: "关联文件名称",
          color: "--text-third-color",
        },
        {
          prop: "sm_status",
          type: "switch",
          label: "使用状态",
          token: "n_D8F6CD85B140555038DB896309AC",
        },
        {
          type: "qrcode",
          label: "试听二维码",
        },
        {
          prop: "u_name",
          label: "创建人",
          color: "--text-third-color",
        },
        {
          prop: "sm_ctime",
          label: "创建时间",
          minWidth: 120,
          color: "--text-third-color",
        },
        {
          type: "operation",
          prop: "",
          label: "操作",
          minWidth: 200,
          bottons: [
            {
              name: "编辑",
              action: "edit",
              token: "n_KUSHN1SDFKI3921A07GEFIDJ5441",
            },
            {
              name: "试听",
              action: "audition",
              token: "n_KSD82J2KFKI3921A07GEFC3AHSUF",
            },
          ],
        },
      ]);
      // 筛选配置
      const filters = ref([
        {
          filterType: "select",
          name: "sm_pid",
          value: "",
          placeholder: "请选择项目",
          options: [],
          val: "p_id",
          lab: "p_name",
        },
        {
          filterType: "select",
          name: "smc_id",
          value: "",
          placeholder: "请选择分类",
          options: [],
          val: "smc_id",
          lab: "smc_name",
        },
        {
          filterType: "search",
          name: "keyword",
          value: "",
          placeholder: "请输入点位编号或关联文件名称搜索",
        },
      ]);
      /**
       * 
       * 操作按钮
       * dow：下载   imp：导入
       * 
       * */
      function openDialog(type) {
        store.dispatch("getQiniuData");
        if (type == "dow") {
          // console.log("下载", store.state.Config.fileUrl);
          const link = document.createElement('a');
          link.download = "点位数据模板";
          link.href = `${baseUrl}/scenic_spot/sound_museum/export_sound_museum.api`;
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          handleRemove();
          uploadRef.value.show();
        }
      }
      const upload = ref(null);
      const fileName = ref("");
      const fileParams = ref(null);
      function customUpload(params) {
        fileName.value = params.file.name;
        fileParams.value = params;
      }
      /**
       * 
       * 新增、编辑点位按钮
       * 
       * */
      const openAddDialog = (row) => {
        if (row.sm_id) {
          //编辑
          isEdit.value = true;
          // const data = JSON.parse(JSON.stringify(row));
          ruleForm.value.sm_pid = row.sm_pid;
          ruleForm.value.sm_smrid = row.sm_smrid;
          ruleForm.value.sm_id = row.sm_id;
          ruleForm.value.associatedFiles = row.smr_name;
        } else {
          // 新增
          isEdit.value = false;
          ruleForm.value = {
            sm_pid: "",  // 项目
            sm_smrid: "",
            associatedFiles: "",  // 关联文件
          };
        }
        dialogRef.value.show();
      };
      /**
      * 
      * 新增/编辑 确认提交按钮
      * 
      * */
      const handleSure = () => {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(ruleForm.value));
            let url = "";
            if (data.sm_id) {
              // 编辑
              url = "editSoundMuseum";
            } else {
              // 新增
              url = "addSoundMuseum";
            }
            ScenicApi[url](data).then((res) => {
              dialogRef.value.isLoading = false;
              const text = data.sm_id ? "修改" : "新增";
              if (res.Code === 200) {
                ElMessage.success(`点位${text}成功！`);
                dialogRef.value.close();
                tableRef.value.tableLoad();
              } else {
                let msg = res.Message ? res.Message : `点位${text}失败！`;
                ElMessage.error(msg);
              }
            });
          }
        });
      };
      /**
      *
      * 试听按钮
      *
      * */
      const audioMedia = ref(null);  // 
      const mp3url = ref(null);
      const onAuditionBtn = (row) => {
        mp3url.value = store.state.Config.fileUrl + row.smr_path;
        // if (type == '1') {

        //   audioMedia.value.play();
        //   // audioMedia.value.paused ? audioMedia.value.play() : audioMedia.value.pause();  //开始、暂停播放
        // } else {
        //   audioMedia.value.pause();
        // }
        setTimeout(function () {
          audioMedia.value.play();
        }, 1000);
      }
      /**
      *
      * 搜索文件
      *
      * */
      const querySearch = (queryString, cb) => {
        if (!queryString) {
          cb([]);
          return false;
        }
        ScenicApi.getSoundFileLists({ keyword: queryString, smr_status: "1" }).then((res) => {
          if (res.Code === 200) {
            cb(res.Data.list);
          } else {
            let msg = res.Message ? res.Message : "获取文件数据失败！";
            ElMessage.error(msg);
          }
        });
      };
      /**
      *
      * 选择文件
      *
      * */
      const handleSelect = (item) => {
        ruleForm.value.sm_smrid = item.smr_id;
        // ruleForm.value.associatedFiles = item.smr_name;
      }
      /**
       * 
       * 文件上传成功
       * 
       * */
      const uploadFile = (data) => {
        fileLists.value.push({ ...data, name: data.name });
      }
      /**
       * 
       * 文件删除
       * 
       * */
      const deleteFile = (data) => {
        let index1 = fileLists.value.findIndex((item) => item.key === data.key);
        fileLists.value.splice(index1, 1);
      }
      /**
       * 
       * 改变状态事件
       * 
       * */
      function statusChange(row) {
        let data = {
          sm_id: row.sm_id,
          sm_status: row.sm_status == 1 ? 2 : 1,  // 1正常 2禁用
        };
        ScenicApi.updateSoundMuseumStatus(data).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("操作成功！");
            tableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 试听二维码
       * 
       * */
      const codeVieWDialog = ref(null);
      const codeUrl = ref("");
      function onSeeBtn(row) {
        codeVieWDialog.value.show();
        codeUrl.value = row.resource_url;
      }
      /**
       * 
       * 删除导入模板
       * 
       * */
      const handleRemove = () => {
        fileName.value = "";
        fileParams.value = "";
        fileLists.value = [];
      }
      /**
       * 
       * 上传最大数量提示
       * 
       * */
      const onExceed = () => {
        ElMessage.warning(`只允许上传1个模板！`);
      };
      /**
       * 
       * 导入模板确认按钮
       * 
       * */
      const onConfirmBtn = () => {
        if (!fileParams.value) {
          ElMessage.error("请上传模板！");
          return false;
        }
        const fd = new FormData();
        fd.append("reservation", fileParams.value.file);
        // fd.append("otr_otid", detailInfo.value.ot_id);
        const url = `${baseUrl}/scenic_spot/sound_museum/import_sound_museum.api`;
        const config = {
          transformRequest: [
            function (data) {
              return data;
            },
          ],
          headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
        };
        axios.post(url, fd, config).then(
          (res) => {
            if (res.status == 200) {
              ElMessage.success("导入成功！");
              uploadRef.value.close();
              tableRef.value.tableLoad();
            } else {
              let msg = res.data.Message ? res.data.Message : "导入失败！";
              ElMessage.error(msg);
            }
          },
          (error) => {
            let msg = error.Message ? error.Message : "导入失败！";
            fileLists.value = [];
            ElMessage.error(msg);
          }
        );
      }
      /**
       * 
       * 导出
       * 
       * */
      function handleExport() {
        const link = document.createElement('a');
        link.href = `${baseUrl}/scenic_spot/sound_museum/export_all_sound_museum.api`;
        link.click();
        URL.revokeObjectURL(link.href);
      }
      onMounted(() => {
        tableRef.value.tableLoad();
        BasicApi.projectSelect().then((res) => {
          if (res.Code === 200) {
            // 新增分类项目选项
            projectOptions.value = res.Data ? res.Data : [];
            filters.value[0].options = res.Data ? res.Data : [];
          } else {
            let msg = res.Message ? res.Message : "获取项目数据失败！";
            ElMessage.error(msg);
          }
        });

        ScenicApi.getClasses().then((res) => {
          // console.log("分类列表", res);
          if (res.Code === 200) {
            filters.value[1].options = res.Data ? res.Data : [];
          } else {
            let msg = res.Message ? res.Message : "数据获取失败！";
            ElMessage.error(msg);
          }
        })
          .catch(() => {

          });
      });


      return {
        ScenicApi,
        tableRef,
        store,
        menuTokens,
        authData,
        openAddDialog,
        tableColumns,
        filters,
        handleExport,
        isEdit,
        dialogRef,
        ruleForm,
        projectOptions,
        rules,
        querySearch,
        handleSure,
        formRef,
        handleSelect,
        openDialog,
        uploadRef,
        uploadFile,
        fileLists,
        onConfirmBtn,
        deleteFile,
        statusChange,
        onSeeBtn,
        codeVieWDialog,
        codeUrl,
        upload,
        customUpload,
        fileName,
        handleRemove,
        onExceed,
        onAuditionBtn,
        audioMedia,
        mp3url,
      };
    },
  };
</script>

<style lang="scss">
  .el-autocomplete {
    width: 100%;
  }

  .upload-files {
    line-height: 40px;
  }

  .tem-upload {
    color: #b3b3b3 !important;
    border-radius: 3px;
    background-color: var(--search-uncheck-bg-color);
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    position: relative;

    &:hover {
      color: #b3b3b3;
    }

    .el-upload {
      width: 100%;
    }
  }

  .content {
    padding: 20px;
  }

  .required {
    color: var(--el-color-danger);
  }
</style>